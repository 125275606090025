// Dev
export const BaseUrlV1 = 'https://apidev.karmm.com/api/v1/';
export const BaseUrlV2 = 'https://apidev.karmm.com/api/v2/';
// prod
// export const BaseUrl = 'https://karmmapi.gtsinfosoft.com/api/v2';

export enum Pattern {
    Email = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
    Mobile = '[0-9]+',
    Password = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$',
    facebookProfile = '(?:https?:\\/\\/)?(?:www\\.)?facebook\\.com\\/(?:(?:\\w)*#!\\/)?(?:pages\\/)?(?:[\\w\\-]*\\/)*([\\w\\-\\.]*)?(\\/)?',
    // instagramProfile = '(?:(?:http|https):\\/\\/)?(?:www\\.)?:?(www)?instagram\\.com\\/([A-Za-z0-9-_]+)?\\S?(\\/)?',
    // instagramProfile = '(?:(?:http|https):\\/\\/)?(?:www\\.)?(?:instagram\\.com)\\/([A-Za-z0-9-_\\.@]+)?(\\/\\?[a-z]{2}=[a-z]{2})',
    instagramProfile = '(?:(?:http|https):\\/\\/)?(?:www\\.)?(?:instagram\\.com)\\/([A-Za-z0-9-_\\.@]+)?(?:(\\/\\?[a-z]{2}=[a-z]{2})|\\/|)',
    youTubeChannel = '((http|https):\\/\\/|)(www\\.|)youtube\\.com\\/(channel\\/|user\\/)[a-zA-Z0-9\\-]{1,}([\\w\\-\\_]*)?(\\/)?',
    twitterProfile = '(?:(?:http|https):\\/\\/)?(?:www\\.)?(?:twitter\\.com)\\/([A-Za-z0-9-_\\.]+)',
    tiktokProfile = '(?:(?:http|https):\\/\\/)?(?:www\\.)?(?:tiktok\\.com)\\/([A-Za-z0-9-_\\.@]+)',
    helpMessageMaxLength = 2000
}

export enum status {
    Pending = 0,
    Approved = 1,
}
