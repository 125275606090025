<div (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()"
     [class.collapsed]="collapsed"
     [class.open]="collapsed && collapsedOpen$ | async"
     class="sidenav flex flex-col">
  <div class="sidenav-toolbar flex items-center gap-2 py-4 flex-col">
    <img src="assets/img/logo_white.png" class="w-24 select-none flex-none">
    <h2 class="title select-none text-lg font-light text-contrast-white flex-auto">Affiliates Panel</h2>
    <!-- <button (click)="toggleCollapse()"
            *ngIf="showCollapsePin$ | async"
            class="w-8 h-8 -mr-2 leading-none flex-none hidden lg:block"
            mat-icon-button
            type="button">
      <mat-icon *ngIf="!collapsed" [icIcon]="icRadioButtonChecked" size="14px"></mat-icon>
      <mat-icon *ngIf="collapsed" [icIcon]="icRadioButtonUnchecked" size="14px"></mat-icon>
    </button> -->
  </div>

  <vex-scrollbar class="flex-auto">
    <div class="sidenav-items">
      <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute"
            [item]="item"
            [level]="0">
      </vex-sidenav-item>
    </div>
  </vex-scrollbar>
</div>
