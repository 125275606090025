import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icDashboard from '@iconify/icons-ic/twotone-dashboard';
import IcGroup from '@iconify/icons-ic/twotone-group';
import IcSettings from '@iconify/icons-ic/twotone-settings';
import IcMoney from '@iconify/icons-ic/twotone-money';
import accountBalance from '@iconify/icons-ic/account-balance';
import IcmoneyOff from '@iconify/icons-ic/money-off'
import IcHelp from '@iconify/icons-ic/twotone-help';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { ConfigName } from '../@vex/interfaces/config-name.model';
import { MatSnackBar } from '@angular/material/snack-bar';




@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Affiliates';

  constructor(private configService: ConfigService,
              private styleService: StyleService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private snackbar : MatSnackBar,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService) {

    Settings.defaultLocale = this.localeId;
                console.log("UPLOADED STABLE BANK DETAILS");
                
    // this.checkUserInactive();

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))),
    ).subscribe(isRtl => {
      this.document.body.dir = isRtl ? 'rtl' : 'ltr';
      this.configService.updateConfig({
        rtl: isRtl
      });
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));


    this.navigationService.items = [
        {
        type: 'link',
        label: 'Dashboard',
        route: '/',
        icon: icDashboard,
      },
      {
        type: 'link',
        label: 'Users',
        route: '/users',
        icon: IcGroup,
      },
      // {
      //   type: 'link',
      //   label: 'Order Commission',
      //   route: '/order-commission',
      //   icon: IcmoneyOff,
      // },
      {
        type: 'link',
        label: 'Withdrawal',
        route: '/withdrawal',
        icon: accountBalance,
      },
      // {
      //   type: 'link',
      //   label: 'Commission Withdrawal',
      //   route: '/commission-withdrawal',
      //   icon: IcMoney,
      // },
      // {
      //   type: 'link',
      //   label: 'How it works',
      //   route: '/how-it-works',
      //   icon: icLayers,
      // },
      {
        type: 'link',
        label: 'Settings',
        route: '/settings',
        icon: IcSettings,
      },
      {
        type: 'link',
        label: 'Help',
        route: '/help',
        icon: IcHelp,
      }

    ];
  }
}
