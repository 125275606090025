import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseUrlV1, BaseUrlV2} from '../utils/systemEnums';
import {DataService} from './data.service';
import {LocalStoreService} from './local-store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceLocal {

  _configuration = {
      API_ENDPOINT : BaseUrlV2,
      API_ENDPOINTV1 : BaseUrlV1
      // API_ENDPOINT_V5: ApiEndPointUrlV5,
      // BANK_API_ENDPOINT : BankListEndPointUrl,
  };

  constructor( private router: Router ,  private _dataService: DataService, private store: LocalStoreService) {

  }

 isLoggedIn() {
    if (this.store.getItem('token')) {
      return true;
    }
    return false;
  }

  getToken() {
    return this.store.getItem('token');
  }

  getUser() {
     return this.store.getItem('loggedUser');
  }


  /**
   *  Auth Module
  * */
  public ChangePassword(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'affliate/affliate-changePassword', body
    );
  }
  public affiliateLogin(body) {
    return this._dataService.postDataWithoutHeader(
        this._configuration.API_ENDPOINT + 'affliate/affliate-login', body
    );
  }
  public affiliateSignUp(body) {
    return this._dataService.postDataWithoutHeader(
        this._configuration.API_ENDPOINT + 'affliate/affliate-signup', body
    );
  }
  public forgotPassword(body) {
    return this._dataService.postDataWithoutHeader(
        this._configuration.API_ENDPOINT + 'affliate/affliate-forgotPassword', body
    );
  }
  public resetPassword(body) {
    return this._dataService.postDataWithoutHeader(
        this._configuration.API_ENDPOINT + 'affliate/affliate-resetPassword', body
    );
  }
  public checkMobileExist(body) {
    return this._dataService.postDataWithoutHeader(
         this._configuration.API_ENDPOINT + 'affliate/numberChecker', body
    );
  }
  public checkEmailExist(body) {
    return this._dataService.postDataWithoutHeader(
         this._configuration.API_ENDPOINT + 'affliate/emailChecker', body
    );
  }

  /**
   * requestWithdrawal Module
   * */
  public getWithdrawalList() {
    return this._dataService.getDataWithHeader(
        this._configuration.API_ENDPOINT + 'affliate/affiliate-withdrawalList'
    );
  }
  public requestWithdrawal(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'affliate/affiliate-requestForWithdrawal', body
    );
  }

  /* 
  * Commission Withdrawal module
  */

  public getCommissionWithdrawalList() {
    return this._dataService.getDataWithHeader(
        this._configuration.API_ENDPOINT + 'affiliate/affiliate-CommisionWithdrawalList'
    );
  }

  public requestCommissionWithdrawal(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'api/v12/account/affiliate-requestForCommisionWithdrawal', body
    );
  }

  /**
   * Get Total Amount
   * */
  public getCommissionWithdrawalTotalAmount() {
    return this._dataService.getDataWithHeader(
        this._configuration.API_ENDPOINT + 'api/v12/account/affiliate-get-total-commision-amount'
    );
  }



  /**
   * How It Works Module
   * */
  public getHowItWorksData(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'api/v6/common/howToAffiliates', body
    );
  }

  /**
   * Dashboard Module
   * */
  public getDashboard() {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'affliate/affiliate-dashBoard', {}
    );
  }
  public generateLink(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'affliate/genrateDynamicLink', body
    );
  }


   
  /**
   * User Detail
   * */
  public getProfile() { 
    return this._dataService.getDataWithHeader(
        this._configuration.API_ENDPOINT + 'api/v6/account/affiliate-getProfile'
    );
  }

  /**
   * Get all Affiliated Detail
   * */
  public getAllAffiliatedUsers() {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'affliate/getListOfAffiliatesJoinedBasedOnLink', ''
    );
  }
  /**
   * Get Total Amount
   * */
  public getTotalAmount() {
    return this._dataService.getDataWithHeader(
        this._configuration.API_ENDPOINT + 'affliate/affiliate-get-total-amount'
    );
  }

  /**
   * Bank Detail
   * */
  public getBankDetailByCountry(country) {
      return this._dataService.getData(
          this._configuration.API_ENDPOINT + 'api/v2/common/get-all-banks/' + country
      );
  }
  public getBranchListByBank(id) {
      return this._dataService.getDataWithHeader(
          this._configuration.API_ENDPOINT + 'api/v6/common/aff-get-branch-list/' + id
      );
  }
  public getBankDetailByUser() {
      return this._dataService.postDataWithHeader(
          this._configuration.API_ENDPOINT + 'affliate/get-affliate-BankDetails', ''
      );
  }
  public addBankDetail(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'affliate/add-affliate-details', body
    );
  }
  public updateBankDetail(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'affliate/update-affliate-details', body
    );
  }

  /**
   * Help Module
   */
  public help(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'affliate/affiliate-help', body
    );
  }
  public uploadDocuments(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'affliate/uploadAffiliateDocument', body
    );
  }
  // public uploadDocumentImage(body) {
  //   return this._dataService.postDataWithHeader(
  //       this._configuration.API_ENDPOINT + 'api/v6/account/uploadAffiliateDocumentImage', body
  //   );
  // }

  public uploadFile(body){
    return this._dataService.postDataWithHeader(
      this._configuration.API_ENDPOINTV1 + 'common/uploadFile', body
    )
  }

  public getUserDocuments() {
    return this._dataService.getDataWithHeader(
        this._configuration.API_ENDPOINT + 'affliate/getAffiliateDocs' 
    );
  }

  public getOrderCommisionForAffiliate(body){
      return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'api/v12/account/getOrderCommisionForAffiliate', body
    );
  }

  // /common/CurrencyList
  public getCurrencyList(){
    return this._dataService.getDataWithoutHeader( 
      this._configuration.API_ENDPOINT + 'affliate/countryList'
    )
  }

}
