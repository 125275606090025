<div class="footer text-center" fxLayout="row">
  <div fxFlex="auto" fxLayout="row" fxLayoutAlign="center center" vexContainer>
<!--    <a class="action"-->
<!--       color="primary"-->
<!--       fxFlex="none"-->
<!--       href="//1.envato.market/0vkRO"-->
<!--       id="get-vex"-->
<!--       mat-raised-button>-->
<!--      <ic-icon [icon]="icShoppingBasket" class="ltr:mr-2 rtl:ml-2" inline="true" size="18px"></ic-icon>-->
<!--      <span>Get Vex (Angular 10+)</span>-->
<!--    </a>-->
      &copy; {{year}} Karmm Affiliates Program
  </div>
</div>
